import React from 'react';
import Layout from '../components/layout'

function IndexPage(props) {
    return (
        <Layout>
            HOME
        </Layout>
    );
}


export default IndexPage;